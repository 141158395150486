module.exports={

                   /* DEV Server URL'S 
   =================================================== */
       URL:'https://sdev.noton.dev/cw-noton/graphql',
       Auth:'https://sdev-auth.mycw.in/cw-identity/oauth/token',
       // networkStatusUrl:'http://localhost:3000/app/'


                  /* Test Server URL'S 
   =================================================== */
        // URL:'https://s20q1.mycw.in/cw-noton/graphql',
        // Auth:'https://s20q1.mycw.in/cw-identity/oauth/token'

                /* Preprod URL'S 
   =================================================== */
         /* URL:'https://sapp.noton.dev/cw-noton/graphql',
         Auth:'https://s360app.mycw.in/cw-identity/oauth/token' */



    
} 
